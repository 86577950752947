import { css } from '@emotion/react';
import { bold } from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

import { fullsize } from '../DestinationMap/styles';

const contentTypography = css`
  max-width: 100%;
  white-space: initial;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const wrapper = css`
  max-width: 1200px;
`;
export const noResultText = css`
  font-weight: 200;
  font-size: 22px;

  padding: 48px 12px;

  ${media.md} {
    padding: 48px 24px;
  }
`;

export const searchWrapper = css`
  position: relative;
  min-height: 100vh;
  & > .sticky-parent {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 40%;
    height: 100%;

    & .sticky {
      & .css-${fullsize.name} {
        height: 100vh;
      }
    }
  }
`;

export const searchPadding = css`
  padding-left: 24px;
  padding-right: 24px;

  ${media.md} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const searchContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  display: inline-block;
  z-index: 1;
  ${media.lg} {
    width: 60%;
  }
  margin-bottom: 24px;
`;

export const mapContainer = css`
  display: none;
  ${media.lg} {
    display: block;
    width: 40%;
  }
`;

export const tiles = css`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  margin-top: 24px;
  justify-content: space-between;

  ${media.xxl} {
    justify-content: unset;
  }
`;

export const secondList = css`
  ${media.md} {
    margin-bottom: 20px;
  }

  ${media.xl} {
    margin-bottom: 50px;
  }
`;

export const gridTile = css`
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;

  ${media.xs} {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  ${media.xxl} {
    flex: 0 0 calc(33.3% - 15px);
    max-width: calc(33.3% - 15px);
    margin-right: 20px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

export const pagination = css`
  margin-bottom: 50px;
`;

export const content = css`
  ${contentTypography}
`;

export const noResultsContainer = css`
  ${mediaMax.md} {
    padding-left: 0;
    padding-right: 0;
  }
  display: flex;
  justify-content: center;
  padding-top: 15px;
  min-height: 200px;
`;

export const noResultsWrapper = css`
  ${mediaMax.md} {
    padding: 0 8px;
  }
  width: 100%;
`;

export const tileWrapper = css`
  width: 100%;
`;

export const modalWrapper = css`
  height: 100%;
`;

export const campsiteOfTheMonthWrapper = css`
  margin-bottom: 50px;
`;

export const campsiteOfTheMonthTitle = css`
  padding-top: 0;
  margin: 0 0 16px;
  text-align: left;
`;

export const summary = css`
  padding: 0;
`;

export const noResultParagraph = css`
  margin-bottom: 20px;
`;

export const infoContainer = css`
  display: flex;
  flex-direction: row;
  justicy-content: space-between;
  padding: 12px 0 36px 0;
`;

export const messageContainer = css`
  margin-left: 6px;
`;

export const message = css`
  font-size: 18px;
  padding-bottom: 8px;
  ${media.xs} {
    margin-right: 8px;
  }
`;

export const infoWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${mediaMax.xs} {
    align-items: flex-start;
  }
`;

export const clickable = css`
  font-weight: ${bold};
  cursor: pointer;
  text-decoration: underline;
`;

export const secondListHeader = css`
  font-size: 22px;
  margin-bottom: 24px;
  width: 100%;
`;

export const saveTheirAss = css`
  display: flex;
  width: 100%;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;
